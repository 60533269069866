
// css
require('../scss/vendor.scss');

// bootstrap
require('@popperjs/core');
window.bootstrap  = require('bootstrap');

// jQuery
window.$ = window.jQuery  = require('jquery');


// plugins
window.moment = require('moment');
window.moment.locale('fr');

require('datatables.net-bs5');
require('datatables.net-fixedcolumns');


window.ApexCharts   = require('apexcharts');
window.apexchartsFR = require('apexcharts/dist/locales/fr.json');

// select2
require('./vendor/select2');

require('jquery-datetimepicker');
require('./vendor/datetimepicker');
require('daterangepicker');

require('cleave.js');
require('../node_modules/cleave.js/dist/addons/cleave-phone.fr');

require('../node_modules/jquery.repeater/jquery.repeater.min.js');
